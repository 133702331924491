* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-width: thin;

  font-family: "Roboto Mono";
  font-size: 62.5%;

  @media (max-width: 1200px) {
    font-size: 56.25%;
  }
  @media (max-width: 900px) {
    font-size: 50%;
  }
  @media (min-width: 1800px) {
    font-size: 75%;
  }
}

*::-webkit-scrollbar {
  width: 8px;
  margin-left: 12px;
}

/* Track */
*::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(170, 170, 170, 0.5);
}
